import React from 'react';
import { FaAngleRight, FaEnvelopeSquare, FaFacebookF, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';


export default function AnewFooter() {
  return (
    <div>
      
        <footer className="footer">    
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60">
                            <div className="row">
                                <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2 footer-logo">
                                    <a href="/" className="logo-footer">
                                        <img src="//res.cloudinary.com/ijeomaonuaju/image/upload/v1707665457/dycomnet/dycomnetWhit_bxgfyl.png" height="24" alt="" style={{maxWidth: '35%'}}/>
                                    </a>
                                    <p className="mt-4">We are a multi vendor ATM support company driven by passion to deliver quality sales and support services to financial service providers in Africa.</p>
                                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li className="list-inline-item mb-0"><a href="/" target="_blank" className="rounded"><FaFacebookF title="facebook"/></a></li>
                                        <li className="list-inline-item mb-0"><a href="/" target="_blank" className="rounded"><FaInstagramSquare title="instagram"/></a></li>
                                        <li className="list-inline-item mb-0"><a href="/" target="_blank" className="rounded"><FaTwitterSquare title="twitter"/></a></li>
                                        <li className="list-inline-item mb-0"><a href="mailto/" className="rounded"><FaEnvelopeSquare title="email" /></a></li>
                                    </ul>
                                </div>
                        
                                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Company</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><a href="/About" className="text-foot"><FaAngleRight  className="me-1"/> About us</a></li>
                                        <li><a href="/Services" className="text-foot"><FaAngleRight  className="me-1"/> Services</a></li>
                                        {/* <li><a href="javascript:void(0)" className="text-foot"><FaAngleRight  className="me-1"/> Team</a></li> */}
                                        <li><a href="/Projects" className="text-foot"><FaAngleRight  className="me-1"/> Project</a></li>
                                    </ul>
                                </div>
                        
                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Usefull Links</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><a href="/" className="text-foot"><FaAngleRight  className="me-1"/> Terms of Services</a></li>
                                        <li><a href="/" className="text-foot"><FaAngleRight  className="me-1"/> Privacy Policy</a></li>
                                    </ul>
                                </div>
    
                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    {/* <h5 className="footer-head"></h5> */}
                                    <p className="mt-4">Send a mail today?</p>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="foot-subscribe mb-3">
                                                    <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                    <div className="form-icon position-relative">
                                                        <i data-feather="mail" className="fea icon-sm icons"></i>
                                                        <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="d-grid">
                                                    <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Send"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-start">
                                <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> Dycomnet. Design with <i className="mdi mdi-heart text-danger"></i> by <a href="../../index.html" target="_blank" className="text-reset">HErExcellency</a>.</p>
                            </div>
                        </div>

                        {/* <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul className="list-unstyled text-sm-end mb-0">
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/american-ex.png" className="avatar avatar-ex-sm" title="American Express" alt=""/></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/discover.png" className="avatar avatar-ex-sm" title="Discover" alt=""/></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/master-card.png" className="avatar avatar-ex-sm" title="Master Card" alt=""/></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/paypal.png" className="avatar avatar-ex-sm" title="Paypal" alt=""/></a></li>
                                <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/visa.png" className="avatar avatar-ex-sm" title="Visa" alt=""/></a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>

      

        <a href="index-blockchain.html#" onclick="topFunction()" id="back-to-top" className="back-to-top fs-5"><i data-feather="arrow-up" className="fea icon-sm icons align-middle"></i></a>
        {/* <!-- Back to top --> */}

        
    </div>
  )};