import React from 'react'
import polaris from '../photo/polaris.png'
import fidelity from '../photo/fidelity.svg'
import bw from '../photo/bw.png'
import accessB from '../photo/accessB.png'


export default function AnewPartners() {
  return (
    // <div className="container">
        <section className="py-4 border-bottom border-top " style={{backgroundColor: '#fff', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap'}}>
           

                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src={accessB} className="reduceIm" alt="" />
                    </div>
                    
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src={fidelity} className="reduceIm" alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src={polaris} className="" alt="" style={{maxWidth: '40%'}}/>
                    </div>
                    <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                        <img src={bw} className="" alt=""/>
                    </div>
                    
        </section>
      
    // </div>
  )
}
