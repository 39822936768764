import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Home';
// import Footer from './components/Footer';
// import Navbar from './components/Navbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './About';
import MediaPage from './MediaPage';
import ContactUs from './ContactUs';
import ContactForm from './ContactForm';
import AnewHead from './components/AnewHead';
import AnewFooter from './components/AnewFooter';
import AnewAbout from './AnewAbout';
import AnewService from './AnewService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardDepositMachine from './Products/CashDepositMachine';
import AtmSales from './Services/AtmSales';
import VaultOpening from './Services/VaultOpening';
import Cassettes from './Services/Cassetes';
import ATMs from './Products/ATMS';
import ATMpart from './Products/ATMpart';
import RefurbishedATM from './Products/RefurbishedATM';
import CashDepositMachine from './Products/CashDepositMachine';
import ATMsecurityDevices from './Products/ATMsecurityDevices';
import FinancialInstantCardPrinter from './Products/FinancialInstantCardPrinter';
import InstantCardPrinters from './Products/InstantCardPrinters';
import CardVendingKiosk from './Products/CardVendingKiosk';
import ForeignNoteCounting from './Products/ForeignNoteCounting';
import ATMRestorationService from './Services/ATMRestorationService';

// import AtmSoftwares from './Services/AtmSoftwares';
// import AtmSoftwares from './Services/AtmSoftwares';

function App() {
  return (
    <Router >
      <div>
      {/* <Navbar /> */}
      <AnewHead />
      
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/About" exact element={<AnewAbout />} />
          <Route path="/Services" exact element={<AnewService />} />
          <Route path="/Projects" exact element={<MediaPage />} />
          <Route path="/Contact-us" exact element={<ContactUs />} />
          <Route path="/ContactForm" exact element={<ContactForm />} />
          <Route path="/Cash-Deposit-machine" exact element={<CardDepositMachine />} />
          <Route path="/ATM-sales-maintenance" exact element={<AtmSales />} />
          <Route path="/ATM-restoration-service" exact element={<ATMRestorationService />} />
          <Route path="/ATM-vault-opening-services-and-key-replacement" exact element={<VaultOpening />} />
           <Route path="/All-brands-of-ATM-cassette-repairs" exact element={<Cassettes />} />
           {/* <Route path="/Atm-refurbishment" exact element={<Refurbishment />} /> */}
           {/* <Route path="/Atm-softwares" exact element={<AtmSoftwares />} /> */}
           <Route path="/Financial-Instant-card-issuance-solution" exact element={<FinancialInstantCardPrinter />} />
           <Route path="/ATMs" exact element={<ATMs />} />
           <Route path="/ATM-part-supply" exact element={<ATMpart />} />
           <Route path="/Refurbished-ATMs" exact element={<RefurbishedATM />} />
           <Route path="/Cash-deposit-machines" exact element={<CashDepositMachine />} />
            <Route path="/ATM-security-devices-and-monitoring-solution" exact element={<ATMsecurityDevices />} />
            <Route path="/Financial-Instant-card-printers" exact element={<FinancialInstantCardPrinter />} />
            <Route path="/Instant-card-printers" exact element={<InstantCardPrinters />} />
            <Route path="/Card-vending-kiosk-machine" exact element={<CardVendingKiosk />} />
            <Route path="/Foreign-note-counting-machines" exact element={<ForeignNoteCounting />} />
        </Routes>
      {/* <Footer /> */}
      <AnewFooter />
      </div>
    </Router>
  );
}

export default App;
