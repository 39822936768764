import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { useNavigate } from 'react-router-dom';
import './css/contact.css';

const ContactUs = () => {
  const [state, handleSubmit] = useForm("xkndkyjy"); // Replace "xkndkyjy" with your Formspree form ID
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        navigate('/'); // Adjust the path as necessary for your homepage
      }, 5000); // Redirect after 5 seconds
    }
  }, [state.succeeded, navigate]);

  return (
    <div>
      <div style={{textAlign: 'center', marginBottom: '40px'}}>
        <h1 className='largeh1'>Latest Projects</h1>
        <p className="largep">Some of the projects we are proud of. We make every project feel personal because our clients matter.</p>
      </div>
      <div className="contact-us-container">
        <div className="contact-form col-lg-6 col-sm-12 col-md-6">
          {showSuccessMessage ? (
            <div className="success-message">
              <h2>Message Successful!</h2>
              <p>You will be redirected shortly...</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <label>
                <input type="text" name="name" placeholder='Your Name' required  className='borderRadius'/>
              </label>
              <label>
                <input placeholder='Your Email' type="email" name="email" id="email" required className='borderRadius'/>
                <ValidationError prefix="Email" field="email" errors={state.errors} />
              </label>
              {/* <label>
                <input placeholder='Message Subject' type="text" name="subject" required className='borderRadius' />
              </label> */}
              <label>
                <textarea placeholder='Your enquiry...' name="message" id="message" required className='borderRadius'></textarea>
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </label>
              <button type="submit" className='borderRadius' disabled={state.submitting}>Submit</button>
            </form>
          )}
        </div>

        <div className="contact-info col-lg-6 col-sm-12 col-md-6">
          <div className="contact-info-inner">
            <div className="contact-icons m30">
              <div>
                <i className='bx bx-envelope bx-tada red'></i> <a href="mailto:support@dycomnetng.com" target="_blank" rel="noopener noreferrer"> support@dycomnetng.com</a>
              </div>
              <div>
                <i className='bx bx-map bx-tada red'></i> Lagos, Nigeria.
              </div>
            </div>
            <div className="contact-map m30">
              <div style={{width: '100%'}}>
                <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=4,%20Yekini%20Okobale%20Street,%20Ago%20Palace,%20Okota,%20Isolo,%20Lagos,%20Nigeria.+(Dycom%20net)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
