import React from 'react'


export default function AnewHead() {
  return (
    <div>
        {/* <div id="preloader">
            <div id="status">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </div>
        </div> */}
        <header id="topnav" className="defaultscroll sticky">
            <div className="container">
                <div className='repair-head'>

                <a className="logo" href="/">
                    <span className="logo-light-mode">
                        <img src="//res.cloudinary.com/ijeomaonuaju/image/upload/v1707665457/dycomnet/dycomnetWhit_bxgfyl.png" className="l-dark plop" height="24" alt=""/>
                        <img src="assets/images/logo-light.png" className="l-light" height="24" alt=""/>
                    </span>
                    <img src="//res.cloudinary.com/ijeomaonuaju/image/upload/v1707665457/dycomnet/dycomnetWhit_bxgfyl.png" height="24" className="logo-dark-mode plop" alt=""/>
                </a>

                <div className="menu-extras">
                    <div className="menu-item">
                        <a className="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                    </div>
                </div>


                <div id="navigation">
                    <ul className="navigation-menu nav-light">
                        <li><a href="/" className="sub-menu-item">Home</a></li>
                        <li><a href="/About" className="sub-menu-item">About</a></li>
                        {/* <li><a href="/Services" className="sub-menu-item">Services</a></li> */}
                        <li className="has-submenu parent-menu-item">
                            <a href="javascript:void(0)">Services</a><span className="menu-arrow"></span>
                            <ul className="submenu ">
                                <li><a href="/ATM-sales-maintenance" className="sub-menu-item">ATM sales/ maintenance</a></li>
                                <li><a href="/ATM-restoration-service" className="sub-menu-item">ATM restoration service</a></li>
                                <li><a href="/ATM-vault-opening-services-and-key-replacement" className="sub-menu-item">ATM vault opening service and key replacement.</a></li>
                                <li><a href="/All-brands-of-ATM-cassette-repairs" className="sub-menu-item">All brands of ATM Cassette repairs.</a></li>
                                <li><a href="/" className="sub-menu-item">ATM refurbishment</a></li>
                                <li><a href="/Atm-refurbishment" className="sub-menu-item">ATM softwares</a></li>
                                <li><a href="/Financial-Instant-card-issuance-solution" className="sub-menu-item">- Financial Instant card issuance solution.</a></li>
                            </ul>
                        </li>
                        <li className="has-submenu parent-menu-item">
                            <a href="javascript:void(0)">Products</a><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><a href="/ATMs" className="sub-menu-item">ATMs</a></li>
                                <li><a href="/ATM-part-supply" className="sub-menu-item">ATM part supply</a></li>
                                <li><a href="/Refurbished-ATMs" className="sub-menu-item">Refurbished ATMs</a></li>
                                <li><a href="/Cash-Deposit-machine" className="sub-menu-item">Cash deposit machines</a></li>
                                <li><a href="/ATM-security-devices-and-monitoring-solution" className="sub-menu-item">ATM security devices and monitoring solution.</a></li>
                                <li><a href="/Financial-Instant-card-printers" className="sub-menu-item">Financial Instant card printers.</a></li>
                                <li><a href="/Instant-card-printers" className="sub-menu-item">Instant card printers</a></li>
                                <li><a href="/" className="sub-menu-item">Instant card printers</a></li>
                                <li><a href="/Card-vending-kiosk-machine" className="sub-menu-item">Card vending kiosk machine.</a></li>
                                <li><a href="/Foreign-note-counting-machines" className="sub-menu-item">Foreign note counting machines.</a></li>
                            </ul>
                        </li>
                        <li><a href="/Projects" className="sub-menu-item">Projects</a></li>
                        <li><a href="/Contact-us" className="sub-menu-item">Contact</a></li>
                        
                    </ul>
                </div>
                </div>
            </div>
        </header>
    </div>
  
)};