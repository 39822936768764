import React from 'react'
import aboutLand from '../photo/projects/aboutLand.jpg'
import wwe from '../photo/wwe.png'
import { FaGlobe, FaHandshake, FaLaptopCode, FaRocket, FaStar } from 'react-icons/fa'
import AnewPartners from '../components/AnewPartners'

export default function Cassettes() {
  return (
    <div>
        <section class="bg-half-170 d-table w-100" style={{backgroundImage: `url(${aboutLand})`}}>
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row mt-5 justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading title-heading">
                            <h2 class="text-white title-dark"> Card Deposit Machine </h2>
                            <p class="text-white-50 para-desc mb-0 mx-auto">Dycom Net is a multi-vendor ATM company designed to deliver cutting edge sales and support services to banks in Africa.</p>
                        </div>
                    </div>
                </div> 
                
                <div class="position-breadcrumb">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item"><a href="index.html">Dycomnet</a></li>
                            <li class="breadcrumb-item"><a href="page-aboutus-two.html#">Company</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Aboutus</li>
                        </ul>
                    </nav>
                </div>
            </div> 
        </section>
        <div class="position-relative">
            <div class="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section class="section">
            <div class="container">
                <div class="row align-items-center" id="counter">
                    <div class="col-md-6">
                        <img src={wwe} class="img-fluid" alt="" />
                    </div>

                    <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="ms-lg-4">
                            <div class="d-flex mb-4">
                                <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" data-target="15">7</span>+</span>
                                <span class="h6 align-self-end ms-2">Years <br/> Experience</span>
                            </div>
                            <div class="section-title">
                                <h4 class="title mb-4">Who we are ?</h4>
                                <p class="text-mutedly"> Together with our partners Banqtec AG (Ratiodata) – the largest multi brand ATM maintenance company in Germany, <span class="text-primary fw-bold"> Dycomnet </span> has the technical capability and spare parts capacity to provide unparalleled support for your NCR, Wincor ATMs and other major brands. <br />We have strategically positioned warehouses and zonal offices in Lagos, Enugu, Port Harcourt and Abuja. We also have branches in other African countries in Accra Ghana and Cameroon</p>
                                <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>
        <AnewPartners />
        <section class="section bg-light">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h6 class="text-primary">Our Commitment</h6>
                            <h4 class="title mb-4">How do we work ?</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Dycomnet</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <FaGlobe className='h3 fa-solid fa-globe'/>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Our Vision</h5>
                                <p class="text-muted mb-0">To build a world class technology support company that exceeds the expectations of our clients while delivering exceptional values to all our stakeholders.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                {/* <i class="uil uil-airplay rounded h3 mb-0"></i> */}
                                <FaRocket className='h3 fa-solid fa-rocket'/>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Our Mission</h5>
                                <p class="text-muted mb-0">To deliver exceptional ATM services to banks and IADS using state-of-the-art technologies delivered by passionate, professional and motivated workforce</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                        <div class="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                            <div class="icons text-center mx-auto">
                                <FaStar className='h3 fa-solid fa-star'/>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">Our Commitment</h5>
                                <p class="text-muted mb-0">We are a team of trained ATM specialists formed to deliver cutting edge sales and support services to banks in Africa.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </section>      
    </div>
  )
}
